import {BigNumberedRow} from "@commons/ui/component/BigNumberedRow";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";

export let HorizontalStepsItem = ({blok}) => {
    let colorProps = blok.Color === 'orange' ? {
        numberContainerClass: "bg-nude",
        numberContainerStyle: {color: '#a85d38'}
    } : {
        numberContainerClass: 'bg-faded-primary'
    }
    return <BigNumberedRow
        position={blok.Position}
        heading={blok.Title}
        text={<RichText content={blok.Text}/>}
        number={blok.Number}
        {...colorProps}
    />
}
