import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";

let breakpoint = 'md'

export let HorizontalStepsContainer = ({blok}) => {
    return <div className="container">
        <div className="row">
            <div className={`offset-${breakpoint}-1 col-${breakpoint}-10 pb-${breakpoint}-6`}>

                <section className="pt-5 pt-md-6">
                    <div className="container py-2">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <h2 className={`mb-4 mb-${breakpoint}-5`}>
                                    {blok.Title}
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>

                <RichText content={blok.Content}/>

            </div>
        </div>
    </div>
}