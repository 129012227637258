import React from "react";
import CenteredColumnLayout from "@commons/ui/layout/CenteredColumnLayout";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";

let CtaSimple1 = ({blok}) => <>
    <div className={"bg-secondary"}>
        <CenteredColumnLayout colClassName={'text-center'}>
            <h1 className="mb-3">
                {blok.Title}
            </h1>
            <p className="fs-lg">
                {blok.Text}
            </p>
            <div className="d-flex align-items-center justify-content-center">
                {!blok.ButtonDisabledText &&
                    <LocalizedLink to={parseI18nLink(blok.ButtonLink)} className="btn btn-primary btn-lg">
                        {blok.ButtonText}
                    </LocalizedLink>}

                {blok.ButtonDisabledText && <span className={"text-muted"}>
                    {blok.ButtonDisabledText}
                </span>}
            </div>
        </CenteredColumnLayout>
    </div>

</>

export default CtaSimple1