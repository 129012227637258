import {getStoryblokApi} from "@storyblok/react";
import {useQuery} from "@commons/infra/helpers";
import {useIntl} from "react-intl";

export let extractImageDimensions = (imgUrl) => {
    let parts = imgUrl.split('/')
    let dimensionsPart = parts[5]

    if (!dimensionsPart || dimensionsPart.indexOf('x') === -1) {
        return {width: null, height: null}
    }
    let dimensionsSplit = dimensionsPart.split('x')

    return {
        width: parseInt(dimensionsSplit[0]) || null, height: parseInt(dimensionsSplit[1]) || null
    }
}

export let useProxiedStoryblokApi = () => {
    let storyblokApi = getStoryblokApi()
    storyblokApi.client.baseURL = window.CONFIG.BACKEND_BASE_URL + '/api/storyblok/proxy'
    storyblokApi.client.headers = {}

    return storyblokApi
}

export let useSbDataFetcher = () => {
    let query = useQuery()
    let storyblokApi = useProxiedStoryblokApi()

    return (localPath, apiPath, apiParams) => {
        if (query.get('no-cache')) {
            return storyblokApi
                .get(apiPath, apiParams)
                .then(r => r.data.story)
        } else {
            return import(`/src/db/sb/${localPath}.json`)
        }
    }
}

export let useSpecificHealthIssuesLoader = () => {
    let {locale} = useIntl()

    return (storyblokSlug) => {
        try {
            return import(`/src/db/sb/health-issues/${storyblokSlug}/_all_${locale}.json`)
                .catch(e => {
                    console.log('asdadasda321 2', e);
                })
        } catch (e) {
            console.log('asdadasda321 3', e);
        }
    }
}