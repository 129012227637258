import React from "react";

let breakpoint = 'md';

export let HorizontalItem = ({children, colWidth, align = 'center'}) => {
    return <div className={`col-12 col-md-6 col-lg-${colWidth} mb-3`}>
        <div className={`mx-auto text-${align}`} style={{maxWidth: '278px'}}>
            {children}
        </div>
    </div>
}

export let HorizontalItem1 = ({img, title, description, colWidth, align = 'center'}) => {
    return <HorizontalItem colWidth={colWidth}>
        <div className={`mb-3 d-flex justify-content-${align}`}>
            {img}
        </div>
        <h3 className="h5 fw-medium mb-2">
            {title}
        </h3>
        <div className="mb-0">
            {description}
        </div>
    </HorizontalItem>
}

export let HorizontalItemNumber = ({number, title, description, colWidth, align = 'center'}) => {
    return <HorizontalItem colWidth={colWidth}>
        <div
            className={`mb-3 justify-content-${align} d-none d-${breakpoint}-flex big-number fw-bold text-muted align-items-center justify-content-center`}
            style={{fontSize: '90px'}}>

            <div className={"rounded-circle bg-faded-primary"} style={{width: '140px'}}>
                {number}.
            </div>
        </div>
        <h3 className="h5 fw-medium mb-2">
            {title}
        </h3>
        <div className="mb-0">
            {description}
        </div>
    </HorizontalItem>
}
