import React, {useState} from "react";

let YouTubeVideo = ({placeholderImageUrl, placeholderVideoId, youtubeVideoId, height = 560, width = 315}) => {
    // eslint-disable-next-line
    let [play, setPlay] = useState(false)
    let onButtonClick = e => {
        e.preventDefault()
        setPlay(true)
    }
    let placeholderImage = placeholderVideoId ? `https://img.youtube.com/vi/${placeholderVideoId}/maxresdefault.jpg` : placeholderImageUrl

    return play ? <iframe width={`${width}`} height={`${height}`}
                          src={`https://www.youtube.com/embed/${youtubeVideoId}?modestbranding=1&showinfo=0&autoplay=1`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/> :
        <VideoPlaceholder onClick={onButtonClick} height={height} placeholderImageSrc={placeholderImage}/>
}

export let VideoPlaceholder = ({
                                   onClick = () => {
                                   },
                                   placeholderImageSrc,
                                   imgHeight,
                                   imgWidth,
                                   imgAlt,
                                   title,
                                   description,
                               }) => {
    return <div
        className={"d-flex align-items-center justify-content-center rounded-3 position-relative z-3"}
        onClick={onClick}
        style={{cursor: 'pointer'}}>

        <img
            className={"rounded-3"}
            src={placeholderImageSrc}
            alt={imgAlt}
            height={imgHeight}
            width={imgWidth}/>

        <div className="btn btn-icon btn-xl btn-danger rounded-circle stretched-link position-absolute">
            <i className="ai-play-filled "></i>
        </div>

        {(title || description) && <>
            <span className="position-absolute top-0 start-0 w-100 h-100 z-1 rounded-3 "
                  style={{background: 'linear-gradient(180deg, rgba(18, 21, 25, 0.00) 70.56%, #121519 95.3%)'}}></span>
            <div className="position-absolute bottom-0 w-100 z-2 p-4">
                <div className="px-md-3">
                    {title && <h5 className="text-white mb-0">
                        {title}
                    </h5>}
                    {description &&
                        <div className="d-flex align-items-center justify-content-between d-none d-lg-block">
                            <span className="text-white fs-xs text-truncate opacity-70 pe-3">{description}</span>
                        </div>}
                </div>
            </div>
        </>}
    </div>
}


export default YouTubeVideo