import PageHeading1 from "@commons/infra/storyblok/components/PageHeading1";
import Page from "@commons/infra/storyblok/components/Page";
import Row1 from "@commons/infra/storyblok/components/Row1";
import VideoSwiper from "@commons/infra/storyblok/components/VideoSwiper";
import Cta from "@commons/infra/storyblok/components/Cta";
import SpecialityDoctorSwiper from "@commons/infra/storyblok/components/SpecialityDoctorSwiper";
import HeroWithVideoSection from "@commons/infra/storyblok/components/HeroWithVideoSection";
import Faq from "@commons/infra/storyblok/components/Faq";
import {Benefits} from "@commons/infra/storyblok/components/Benefits";
import {Steps} from "@commons/infra/storyblok/components/Steps";
import {Doctors} from "@commons/infra/storyblok/components/Doctors";
import HeroWithPictureSection from "@commons/infra/storyblok/components/HeroWithPictureSection";
import {RowV2} from "@commons/infra/storyblok/components/RowV2";
import {QaGameSponsorsSection} from "@commons/infra/storyblok/components/pagespecific/QaGameSponsorsSection";
import {LatvianStatisticsSectionStoryblokWrapper} from "@commons/infra/storyblok/components/LatvianStatisticsSection";
import {QuoteSectionStoryblokWrapper} from "@commons/infra/storyblok/components/QuoteSection";
import {
    LatvianPublicationsSwiperSectionStoryblokWrapper
} from "@commons/infra/storyblok/components/LatvianPublicationsSwiperSection";
import {CtaWithArrow} from "@commons/infra/storyblok/components/CtaWithArrow";
import CtaSimple1 from "@commons/infra/storyblok/components/CtaSimple1";
import {HorizontalStepsContainer} from "@commons/infra/storyblok/components/HorizontalStepsContainer";

let storyblokComponentsMapping = {
    SpecialityPage: Page,
    LandingPage: Page,
    HeroWithVideo: HeroWithVideoSection,
    HeroWithPicture: HeroWithPictureSection,
    PageHeading1: PageHeading1,
    Row1: Row1,
    RowV2: RowV2,
    VideoSwiper: VideoSwiper,
    Cta: Cta,
    CtaWithArrowSection: CtaWithArrow,
    CtaSimple1: CtaSimple1,
    HorizontalStepsContainer: HorizontalStepsContainer,
    FAQ: Faq,
    SpecialityDoctorSwiper: SpecialityDoctorSwiper,
    Benefits: Benefits,
    Steps: Steps,
    Doctors: Doctors,
    LatvianStatisticsSection: LatvianStatisticsSectionStoryblokWrapper,
    LatvianPublicationsSwiperSection: LatvianPublicationsSwiperSectionStoryblokWrapper,
    QuoteSection: QuoteSectionStoryblokWrapper,

    // Page specific
    QaGameSponsorsSection: QaGameSponsorsSection,
}

export default storyblokComponentsMapping
