import React from "react";
import {breakpoint} from "../layout/common";

export let BigNumberedRow = ({
                                 position, heading, text, number, numberContainerClass = '', numberContainerStyle = {}
                             }) => {
    let order1 = position === 'right' ? 2 : 1
    let order2 = position === 'right' ? 1 : 2

    return <section className="container">
        <div className={`row align-items-center pt-xl-3 my-${breakpoint}-4`}>
            <div
                className={`col-md-6 col-lg-5 offset-lg-1 order-${breakpoint}-${order1} d-none d-${breakpoint}-block `}
                data-jarallax-element="25"
                data-disable-parallax-down="md">
                <div className={`mx-auto mx-md-0 text-center rounded-3 ${numberContainerClass}`}
                     style={numberContainerStyle}>
                    <span className={"huge-text"}>{number}.</span>
                </div>
            </div>
            <div
                className={`col-md-6 col-lg-5 offset-${breakpoint}-1 order-${breakpoint}-${order2} py-${breakpoint}-5`}>
                <div className="mx-auto">
                    <h3 className="pb-2">
                        <span className={`d-inline d-${breakpoint}-none`}>{number}. </span>
                        {heading}
                    </h3>
                    <p className="pb-2">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    </section>
}
