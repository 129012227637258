export let DifferentBackgroundSection = ({bg, className, children}) => {
    return <div className={`${bg ? `bg-${bg}` : ''} ${className}`}>
        <div className={`container py-4 py-lg-3 py-xl-5 py-xxl-5`}>
            {children}
        </div>
    </div>
}

export let SameBackgroundSection = ({bg, children}) => {
    return <div className={`${bg ? `bg-${bg}` : ''}`}>
        <div className={`container pt-0 pb-4 pt-lg-0 pb-lg-3 pt-xl-0 pb-xl-5 pt-xxl-0 pb-xxl-5`}>
            {children}
        </div>
    </div>
}

// V2 has bigger paddings

export let DifferentBackgroundSectionV2 = ({bg, children}) => {
    return <div className={`${bg ? `bg-${bg}` : ''}`}>
        <div className={`container py-4 py-lg-4 py-xl-7 py-xxl-7`}>
            {children}
        </div>
    </div>
}

export let SameBackgroundSectionV2 = ({className = '', bg, children}) => {
    return <div className={`${className} ${bg ? `bg-${bg}` : ''}`}>
        <div className={`container pt-0 pb-5 pt-lg-0 pb-lg-5 pt-xl-0 pb-xl-7 pt-xxl-0 pb-xxl-7`}>
            {children}
        </div>
    </div>
}